import React from "react"
import { MatrixEntity } from "../../entities/GameEntity"
import { Cel } from "../Cel/Cel"

type Props = {
  matrix: MatrixEntity
  size: string
}

export const Grid: React.FC<Props> = props => {
  return (
    <div className="flex sm:items-center justify-center w-full px-2 sm:px-0">
      <div
        className="w-full"
        style={{
          borderLeftWidth: "1px",
          borderLeftStyle: "solid",
          borderTopWidth: "1px",
          borderTopStyle: "solid",
        }}
      >
        {props.matrix.map((cols, index) => (
          <div
            className={`grid relative w-full
              ${props.size === "4x4" && "grid-cols-4"}
              ${props.size === "5x5" && "grid-cols-5"}
              ${props.size === "6x6" && "grid-cols-6"}
              ${props.size === "7x7" && "grid-cols-7"}
              ${props.size === "8x8" && "grid-cols-8"}
              ${props.size === "9x9" && "grid-cols-9"}
            `}
            key={index}
          >
            {cols.map(cel => (
              <Cel id={cel.id} key={cel.id} />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}
