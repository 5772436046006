export const getMode = () => {
  return "/app/"
}

export const getPlay = () => {
  return "/app/play/"
}

export const getSelection = () => {
  return "/app/classical/"
}

export const getTutorial = () => {
  return "/app/tutorial/"
}

export const getChallenge = () => {
  return "/app/challenges/"
}
