import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState, props: { id: string }) => {
  const [row, col] = props.id.split(":")
  const cel = state.play.actual?.matrix[row][col]
  const size = state.play.actual?.size
  const selected = state.play.selected || "1000000:1000000"
  const [rowSelected, colSelected] = selected.split(":")
  const isAdjacent = state.play.adjacent.includes(cel.id)
  const isSelected = cel.id === state.play.selected

  const isBottomAdjacent = isAdjacent
    ? state.play.adjacent.findIndex(id => cel.id === id) === 0
    : false
  const isRightAdjacent = isAdjacent
    ? state.play.adjacent.findIndex(id => cel.id === id) === 3
    : false

  const sameRowLine =
    !selected || isSelected ? false : rowSelected === row || colSelected === col

  return {
    ...cel,
    number: cel.number,
    size,
    selected: cel.id === state.play.selected,
    isBottomAdjacent,
    isRightAdjacent,
    sameRowLine,
  }
}

const mapDispatch = (dispatch: any, props: { id: string }) => ({
  onChange: (value: number) => {
    dispatch(
      actions.play.fetchChangeCel({
        value: value ? Number(value) : value,
      })
    )
  },
  onClick: () => {
    dispatch(
      actions.play.selectCel({
        id: props.id,
      })
    )
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
