import React from "react"

const Square = ({ position, size, translate }) => (
  <div className={`transform duration-1000 ease-in-out ${translate}`}>
    <div
      className={`absolute ${position}`}
      style={{
        animationName: "bounce",
        animationDuration: "10s",
        animationIterationCount: "infinite",
        animationTimingFunction: "ease-in-out",
      }}
    >
      <div
        className={`${size} border-2 rounded border-gray-200`}
        style={{
          animationName: "spin",
          animationDuration: "60s",
          animationIterationCount: "infinite",
          animationTimingFunction: "linear",
        }}
      ></div>
    </div>
  </div>
)

const Circle = ({ position, size, translate }) => (
  <div className={`transform duration-1000 ease-in-out ${translate}`}>
    <div
      className={`absolute ${position} ${size} rounded-full border-2 border-gray-200`}
      style={{
        animationName: "bounce",
        animationDuration: "20s",
        animationIterationCount: "infinite",
        animationTimingFunction: "linear",
      }}
    ></div>
  </div>
)

const Cross = ({ position, size, translate, delay = "0ms" }) => (
  <div
    className={`transform duration-1000 ease-in-out ${translate} absolute ${position}`}
  >
    <div
      style={{
        animationName: "bounce",
        animationDuration: "20s",
        animationIterationCount: "infinite",
        animationTimingFunction: "linear",
      }}
    >
      <div
        style={{
          animationName: "spin",
          animationDuration: "60s",
          animationIterationCount: "infinite",
          animationTimingFunction: "linear",
          animationDelay: delay,
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className={`${size} text-gray-200`}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={1}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>
    </div>
  </div>
)

export type Props = {
  step: number
  error?: boolean
  absolute?: boolean
}

export const BackgroundShapes: React.FC<Props> = props => {
  const isPair = props.step % 2 === 0

  return (
    <div
      className={`${
        props.absolute ? "absolute" : "fixed"
      } top-0 bottom-0 left-0 right-0 hidden lg:block`}
    >
      <Square
        position="right-2 top-20"
        size="w-44 h-44"
        translate={`${
          !props.error
            ? isPair && "-translate-x-20 translate-y-10"
            : "translate-x-full"
        } `}
      />

      <Cross
        position="right-2 top-20"
        size="w-44 h-44"
        delay="10000ms"
        translate={`${!props.error && "translate-x-full"} `}
      />

      <Square
        position="left-32 top-96"
        size="w-24 h-24"
        translate={`${
          !props.error
            ? isPair && "-translate-x-5 translate-y-20"
            : "-translate-x-full"
        } `}
      />
      <Cross
        position="left-0 bottom-24"
        size="w-24 h-24"
        translate={`${!props.error && "-translate-x-full"} `}
      />

      <Circle
        position="-left-6 top-32"
        size="w-60 h-60"
        translate={`${
          !props.error
            ? isPair && "-translate-x-20 translate-y-10"
            : "-translate-x-full"
        } `}
      />

      <Cross
        position="left-6 top-40"
        size="w-60 h-60"
        delay="10000ms"
        translate={`${!props.error && "-translate-x-full"} `}
      />

      <Circle
        position="right-6 top-96"
        size="w-36 h-36"
        translate={`${
          !props.error
            ? isPair && "-translate-x-5 translate-y-20"
            : "translate-x-full"
        } `}
      />

      <Cross
        position="right-6 bottom-0"
        size="w-36 h-36"
        translate={`${!props.error && "translate-x-full"}`}
      />
    </div>
  )
}
