import React from "react"
import { CelEntity } from "../../entities/GameEntity"
import { connector, ContainerProps } from "./containers/Cel.containers"

type Props = CelEntity & {
  onClick: Function
  size: string
  selected: boolean
  isRightAdjacent: boolean
  isBottomAdjacent: boolean
  sameRowLine: boolean
}

export const CelWrapper: React.FC<Props> = props => {
  const containerStyle = {
    base: "relative bg-white w-full",
    "4x4": "",
    "5x5": "",
    "6x6": "",
    "7x7": "",
    "8x8": "",
    "9x9": "",
  }

  const celStyle = {
    base:
      "flex select-none transform bg-white transition duration-300 ease-in-out text-center text-gray-800 font-medium items-center justify-center border-none absolute top-0 left-0 h-full w-full",
    "4x4": "text-4xl",
    "5x5": "text-2xl",
    "6x6": "text-lg",
    "7x7": "text-lg",
    "8x8": "text-lg",
    "9x9": "text-lg",
  }

  const arrowStyle = {
    base: "text-gray-500 transform transition duration-300 ease-in-out",
    "4x4": "h-8 w-8",
    "5x5": "h-8 w-8",
    "6x6": "h-6 w-6",
    "7x7": "h-6 w-6",
    "8x8": "h-6 w-6",
    "9x9": "h-4 w-4 sm:h-6 sm:w-6",
  }

  return (
    <div className="w-full">
      <div
        className={`${containerStyle.base} ${containerStyle[props.size]}`}
        style={{
          paddingTop: "100%",
          borderRightWidth: "1px",
          borderBottomWidth: "1px",
          borderRightStyle: props.right ? "dashed" : "solid",
          borderBottomStyle: props.bottom ? "dashed" : "solid",
          borderRightColor: props.right ? "rgba(0,0,0,0.05)" : "inherit",
          borderBottomColor: props.bottom ? "rgba(0,0,0,0.05)" : "inherit",
        }}
      >
        <div
          onClick={e => !props.default && props.onClick()}
          className={`${celStyle.base} ${celStyle[props.size]} ${
            props.error && "text-red-400 animate-headShakeOnce"
          } ${props.default && "bg-gray-100"} ${
            !!props.default ? "cursor-not-allowed" : "cursor-pointer"
          } ${props.selected && "shadow-xl ring-1 z-10 scale-110"}
          ${props.sameRowLine ? "bg-blue-50" : ""}
          `}
        >
          <div>{props.number}</div>
          {!props.number && (
            <div className="grid grid-cols-3 w-full h-full">
              {props.drafts.map((value, index) => (
                <div
                  className={`text-2xs sm:text-xs transition duration-300 ease-in-out flex items-center justify-center select-none ${
                    value ? "text-gray-500" : "text-white"
                  } `}
                  key={index}
                >
                  {value || <>&nbsp;</>}
                </div>
              ))}
            </div>
          )}
        </div>

        {props.right && (
          <div className="absolute z-20 right-0 top-0 bottom-0 flex items-center my-auto transform translate-x-1/2 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className={`${arrowStyle.base} ${arrowStyle[props.size]} ${
                props.selected && "translate-x-1/2"
              } ${props.isRightAdjacent && "-translate-x-1/2"}`}
            >
              {props.right === "gt" && (
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              )}
              {props.right === "lt" && (
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              )}
            </svg>
          </div>
        )}
        {props.bottom && (
          <div className="absolute z-20 right-0 bottom-0 left-0 flex justify-center transform translate-y-1/2 pointer-events-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className={`${arrowStyle.base} ${arrowStyle[props.size]} ${
                props.selected && "translate-y-1/2"
              } ${props.isBottomAdjacent && "-translate-y-1/2"}`}
            >
              {props.bottom === "lt" && (
                <path
                  fillRule="evenodd"
                  d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              )}
              {props.bottom === "gt" && (
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              )}
            </svg>
          </div>
        )}
      </div>
    </div>
  )
}

export const CelContainer: React.FC<ContainerProps> = props => (
  <CelWrapper {...props} />
)

export const Cel = connector(CelContainer)
